import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { PodcastContext } from 'src/components/PodcastLayout'
import styles from './ListenPodcast.module.scss'
import PlayButton from 'src/components/svg/PlayButton'
class ListenPodcast extends Component {
  render() {
    const { podcast, podcastTitle, podcastGuest, style, isMobileHidden, intro, podcastCover, parentSlug } = this.props

    return (
      <PodcastContext.Consumer>
        {(contextValue) => {
          const { onChangeData } = contextValue
          return (
            <div
              style={style}
              className={classNames(styles.callToAction, {
                [styles.intro]: intro,
              })}
              onClick={
                podcast
                  ? () => onChangeData(podcast, podcastTitle, podcastGuest, podcastCover, parentSlug)
                  : () => console.log('Something went wrong!')
              }
            >
              <>
                <span
                  className={classNames(styles.tag, {
                    [styles.isMobileHidden]: isMobileHidden,
                  })}
                />
                <div className={styles.playButton}>
                  <PlayButton height="56px" width="56px" aria-label="play" />
                </div>
              </>
            </div>
          )
        }}
      </PodcastContext.Consumer>
    )
  }
}

ListenPodcast.propTypes = {
  podcast: PropTypes.string.isRequired,
  podcastTitle: PropTypes.string.isRequired,
  podcastGuest: PropTypes.string.isRequired,
  podcastCover: PropTypes.object,
  style: PropTypes.object,
  isMobileHidden: PropTypes.bool,
}

export default ListenPodcast
