import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListenPodcast from './ListenPodcast'
import SocialShare from 'src/components/SocialShare'
import Img from 'gatsby-image'
import styles from './SinglePodcast.module.scss'
import { getBaseUrl } from 'src/utils'

class SinglePodcast extends Component {
  handleClickPlay = () => {
    this.setState({ isPlaying: !this.state.isPlaying })
  }

  render() {
    const { title, podcast, podcastTitle, podcastCover, podcastGuest, locale, parentSlug, duration } = this.props
    const podcastStyle = { position: 'static' }

    return (
      <section className={styles.root}>
        <figure className={styles.container}>
          <Img className={styles.mainPodcast} fluid={podcastCover.fluid} />
          <SocialShare
            title={title}
            className={styles.share}
            url={`${getBaseUrl()}${locale}/podcast/${parentSlug}`}
            isWhite
            locale={locale}
          />
          <ListenPodcast
            style={podcastStyle}
            podcast={podcast}
            locale={locale}
            podcastTitle={podcastTitle}
            podcastGuest={podcastGuest}
            podcastCover={podcastCover}
            parentSlug={parentSlug}
          />
          <div className={styles.duration}>{duration}</div>
        </figure>
        <div className={styles.title}>{title}</div>
      </section>
    )
  }
}

SinglePodcast.propTypes = {
  title: PropTypes.string.isRequired,
  podcast: PropTypes.string.isRequired,
  podcastTitle: PropTypes.string.isRequired,
  podcastCover: PropTypes.object.isRequired,
  podcastGuest: PropTypes.string.isRequired,
  locale: PropTypes.string,
  parentSlug: PropTypes.string,
  duration: PropTypes.string,
}

export default SinglePodcast
