import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { getBaseUrl } from 'src/utils'
import SubscribeAdditional from 'src/components/Subscribe'
import Footer from 'src/components/Footer'
// import Home from 'src/components/Home'
import AudiosList from '../components/AudiosList'
import Header from 'src/components/Header'
import PageHelmet from 'src/components/PageHelmet'
import SinglePodcast from 'src/components/SinglePodcast'
import withLayout from 'src/components/withLayout'
import { FormattedMessage } from 'react-intl'

const podcastsToShow = 9

class PodcastTemplate extends Component {
  state = {
    showAllPodcasts: false,
  }

  toggleShowAllPodcasts = () => {
    this.setState({ showAllPodcasts: !this.state.showAllPodcasts })
  }

  render() {
    const {
      data: {
        subscribe,
        podcasts: { edges: audios },
        podcast: {
          title,
          duration,
          guest,
          cover,
          cover: { url: imageUrl },
          podcast: { url: podcastFile },
          // ad,
          slug,
        },
      },
      pageContext: { originalPath },
      locale,
    } = this.props

    const { showAllPodcasts } = this.state

    return (
      <>
        <PageHelmet title={title} url={`${getBaseUrl()}${locale}${originalPath}`} image={imageUrl} defer={false} />
        <main>
          <Header full={true} {...this.props} locale={locale} />
          <SinglePodcast
            title={title}
            podcast={podcastFile}
            duration={duration}
            podcastTitle={title}
            podcastGuest={guest}
            podcastCover={cover}
            locale={locale}
            parentSlug={slug}
          />
          <AudiosList
            audios={audios.filter(({ node }) => node.slug !== slug).slice(0, podcastsToShow)}
            locale={locale}
            hideSortPanel
            addMarginTop
          />
          {showAllPodcasts && (
            <AudiosList
              audios={audios.filter(({ node }) => node.slug !== slug).slice(podcastsToShow)}
              locale={locale}
              hideSortPanel
            />
          )}
          {!showAllPodcasts &&
            audios.length > podcastsToShow + 1 && (
              <button onClick={this.toggleShowAllPodcasts} className="btnMore">
                <FormattedMessage id="home.more" />
              </button>
            )}
          {/* <Home onChangeData={this.props.onChangeData} locale={locale} ad={ad} /> */}
          <SubscribeAdditional locale={locale} subscribe={subscribe} />
        </main>
        <Footer isInHome locale={locale} />
      </>
    )
  }
}

export const query = graphql`
  query Podcast($id: String!, $locale: String!) {
    podcast: datoCmsPodcast(id: { eq: $id }) {
      title
      duration
      publishDate
      cover {
        url
        id
        fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      guest
      podcast {
        url
      }
      slug
    }
    podcasts: allDatoCmsPodcast(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
          duration
          publishDate
          guest
          cover {
            url
            id
            fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          podcast {
            url
          }
          slug
        }
      }
    }
    news: allDatoCmsNews(
      filter: { ismain: { eq: true }, locale: { eq: $locale } }
      sort: { fields: [publishdate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          publishdate(formatString: "DD.MM.YYYY")
          slug
          title
          description
          ismain
          image {
            id
            fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          author
        }
      }
    }
    subscribe: file(relativePath: { eq: "subscribe-illustration.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    promotion: allDatoCmsPromotion(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          autoplayDelay
          slides {
            id
            title
            image {
              url
            }
            link
          }
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'podcast',
}

export default withLayout(customProps)(PodcastTemplate)
